
.horizontal {
  transform: rotate(90deg);
  width: auto;
  height: auto;
}


@media (min-width: 768px) {
  .horizontal {
    transform: rotate(0deg);
  }
}

.list-dis {
  list-style-type: disc;
  margin-left: 1em;
}
